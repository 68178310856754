import { Link, useOutletContext } from "@remix-run/react";
import * as classes from "./CTA.css";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { OutletContext } from "~/types/shared";

export function CTA({ text, href }: { text: string; href: string }) {
  const { prefetchType } = useOutletContext<OutletContext>();

  return (
    <Link to={href} className={classes.cta} prefetch={prefetchType}>
      {text} <IconArrowNarrowRight />
    </Link>
  );
}
