import { CTA } from "~/components/landing-page/CTA/CTA";
import image from "~/images/landing-page/hero-bg.jpg";
import { EXPLORE } from "~/config/routes";
import * as classes from "./Hero.css";

export function Hero() {
  return (
    <header className={classes.hero}>
      <div className={classes.content}>
        <h1 className={classes.heading}>
          {`Your ultimate
dive log experience`}
        </h1>
        <h2 className={classes.subHeading}>
          Track your adventures, discover new underwater wonders and contribute
          to ocean conservation — all in one place
        </h2>
        <CTA text="Dive in" href={EXPLORE} />
        <div className={classes.bgImageContainer}>
          <img
            className={classes.bgImage}
            src={image}
            alt="Free diver swimming towards the surface next to a coral reef with small orange fish"
          />
        </div>
      </div>
    </header>
  );
}
