import { useRef, useState, useEffect } from "react";
import {
  IconDevices,
  IconScubaDiving,
  IconThumbUp,
  IconUsersGroup,
} from "@tabler/icons-react";
import * as classes from "./Snippets.css";

function Snippet({ children }: { children: React.ReactNode }) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const el = containerRef.current;

    const observer = new IntersectionObserver(
      function (entries) {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, []);

  return (
    <div
      className={isVisible ? classes.snippetVisible : classes.snippet}
      ref={containerRef}
    >
      {children}
    </div>
  );
}

export function Snippets() {
  return (
    <section className={classes.snippets}>
      <div className={classes.row}>
        <Snippet>
          <IconScubaDiving className={classes.icon} />
          <h4 className={classes.heading}>Research dive companies</h4>
          <div className={classes.copy}>
            <p>
              Our platform lets you filter and compare centres, resorts,
              liveaboard and operators based on real diver reviews.
            </p>
            <p>
              Whether you’re planning a local dive or an exotic getaway, find
              trusted operators that match your needs and ensure a memorable
              experience.
            </p>
          </div>
        </Snippet>

        <Snippet>
          <IconThumbUp className={classes.icon} />
          <h4 className={classes.heading}>Leave ratings and reviews</h4>
          <div className={classes.copy}>
            <p>
              Whether reviewing a resort, dive centre, live-aboard, or local
              operator, your feedback on service quality, health and safety
              standards and eco-friendliness helps raise industry standards.
            </p>
            <p>This ensures the best experience for all future divers.</p>
          </div>
        </Snippet>
      </div>
      <div className={classes.row}>
        <Snippet>
          <IconUsersGroup className={classes.icon} />
          <h4 className={classes.heading}>Catalogue group trips</h4>
          <div className={classes.copy}>
            <p>
              Easily catalogue your dive trips, organise them by destination,
              and invite your friends to contribute.
            </p>
            <p>
              Share photos, log dives, and connect with others to relive your
              best underwater moments together.
            </p>
          </div>
        </Snippet>
        <Snippet>
          <IconDevices className={classes.icon} />
          <h4 className={classes.heading}>No app required</h4>
          <div className={classes.copy}>
            <p>
              Our web-based platform works seamlessly across all your devices.
            </p>
            <p>
              Whether you&apos;re uploading a dive log from your phone whilst
              cruising the Red Sea, or from the comfort of your home on a
              laptop, you can dive into our platform without limitations.
            </p>
          </div>
        </Snippet>
      </div>
    </section>
  );
}
