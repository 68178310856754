import * as classes from "../ParallaxyBanners.css";

export function Banner({
  heading,
  copy,
  imageSrc,
  imageAlt,
  textRight,
  icon,
  index,
}: {
  heading: string;
  copy: string[];
  imageSrc: string;
  imageAlt: string;
  textRight?: boolean;
  icon: React.ReactNode;
  index: number;
}) {
  return (
    <section
      className={classes.banner}
      style={{
        // @ts-expect-error !?!
        "--index": index,
      }}
    >
      <div className={textRight ? classes.textRight : classes.bannerContent}>
        <div className={classes.copy}>
          <div className={classes.icon}>{icon}</div>
          <h3 className={classes.heading}>{heading}</h3>
          <div>
            {copy.map((p, i) => (
              <p key={i} className={classes.paragraph}>
                {p}
              </p>
            ))}
          </div>
        </div>
        <img
          className={classes.image}
          src={imageSrc}
          alt={imageAlt}
          loading="lazy"
        />
      </div>
    </section>
  );
}
