import { useEffect, useRef, useState } from "react";
import image from "~/images/landing-page/data-pledge.png";
import * as classes from "./DataPledge.css";

export function DataPledge() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const el = containerRef.current;

    const observer = new IntersectionObserver(
      function (entries) {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, []);

  return (
    <section
      className={isVisible ? classes.bannerVisible : classes.banner}
      ref={containerRef}
    >
      <div className={classes.copy}>
        <h2 className={classes.heading}>Mission</h2>
        <h3 className={classes.subHeading}>Our ocean data pledge</h3>
        <p>
          Our oceans need a voice, and your dive data can help give them one. By
          logging your dives, you provide valuable data points that empower
          researchers in their fight to protect marine life.
        </p>
        <p>
          We promise to put your data in the hands of scientists,
          conservationists, and organisations that are dedicated to making a
          real difference. Let your dives drive action.
        </p>
        <p>
          Are you a scientist, conservationist, or part of an organisation
          working to protect our oceans? Get in touch with us to request access
          to our dive data and discover how it can support your research and
          conservation efforts.
        </p>
      </div>
      <img className={classes.image} src={image} alt="" loading="lazy" />
    </section>
  );
}
