import { useEffect, useRef, useState } from "react";
import { useWindowScroll } from "@mantine/hooks";
import * as classes from "./ImagesSlider.css";

const images = [
  "https://auth.scuba.network/storage/v1/render/image/public/photos/jacks__e294a052-1fb4-41ba-9a4b-7480f1f486ac.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_4262__dae308f7-cc8c-4c53-9d45-b93352533b24.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC07013__33513f3f-3368-4763-a071-fa268b61bc3a.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_4260__a086fc1f-31bd-431e-b4ee-0b13e48e9789.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_4328__c41f0153-e356-4e66-9374-de99a8b98062.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/plane-wreck__17e75205-8de7-4b0e-b30e-146ee926db0f.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_5035__c6b41afc-a5f4-48ca-9b51-5fb456172e2d.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/Screenshot%202024-06-19%20at%2008__173f4c1b-1829-4e92-8bde-9b0b6bbc66ee.26?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/setup__37306317-2b3e-444e-bf3d-e57694816775.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/shark-turn__8441f4a5-f86d-46b2-ae05-a52459188231.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/chad-sharks__7d33e2e2-4912-42f2-a109-a643e19dbada.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_4268__a69acf62-4cee-46e5-b95c-41f97f6567d5.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06546__df8d2ba6-0b7f-4c5d-b929-b9cc648768eb.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_4251__b8e5c690-d0d2-4437-80db-ff9ad596eae9.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/jellyfish-2__4e51f275-6162-45d7-bc56-90087abe3795.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06569__a6ef7920-6889-4bf3-b342-d0f46657bef5.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06611__86503614-f048-4a81-91e2-70ff62aa2d29.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC05823__2a9249c2-acf6-47f4-8280-7d0bda844d81.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06720__144e726d-984f-4f31-9ce3-7a3811990c5e.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06432__594e7b78-b562-4262-a93e-ce8189f13768.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06901__fdf6ce97-d616-480a-bce3-2cc8713cd590.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_5026__d7ca9888-e483-41d8-924a-8b296896c091.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06708__0980dc2a-327d-4705-ab2c-1a27f3add5af.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC05769__24cc80c4-7138-4654-9d98-ae90873701f3.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06152__51b62916-b78e-4c9c-9ba2-de3c4dd7e398.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/IMG_4815__98bed87a-8aed-4cec-bf27-2e52d3c1f96b.jpeg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/stingray-1__11e05384-fe3d-41aa-8643-68f3c440624a.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC06965__53bafe17-23cc-4c64-bc42-535ba89ff0b0.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC07508__6e916661-929f-402c-a032-3ae2d6db6f99.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/chad-shark__ebea939f-afaf-43f2-b25d-8a365931d04e.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/DSC07630-2__6b8fae70-37d3-49e7-ac65-2dc662cdbb4c.jpg?width=420&height=420&resize=contain",
  "https://auth.scuba.network/storage/v1/render/image/public/photos/hermit-crab__863eccfe-d816-48df-80bf-f4a93b9e06c0.jpg?width=420&height=420&resize=contain",
];

const imagesFirstHalf = images.slice(0, images.length / 2);
const imagesSecondHalf = images.slice(images.length / 2, images.length);
const MULTIPLIER = 10;

export function ImagesSlider() {
  const [scroll] = useWindowScroll();
  const containerRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const containerTop = container.offsetTop;

      setOffset(containerTop - scroll.y);
    }
  }, [scroll.y]);

  return (
    <div className={classes.container} ref={containerRef}>
      <div
        className={classes.imagesRow}
        style={{
          transform: `translateX(calc(-100% + ${offset / MULTIPLIER}px))`,
        }}
      >
        {[...imagesFirstHalf, ...imagesSecondHalf].map((imgSrc, i) => (
          <div key={i} className={classes.imageContainer}>
            <img className={classes.image} src={imgSrc} alt="" loading="lazy" />
          </div>
        ))}
      </div>
      <div
        className={classes.imagesRow}
        style={{
          transform: `translateX(calc(-50% - ${offset / MULTIPLIER}px))`,
        }}
      >
        {[...imagesSecondHalf, ...imagesFirstHalf].map((imgSrc, i) => (
          <div key={i} className={classes.imageContainer}>
            <img className={classes.image} src={imgSrc} alt="" loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
}
