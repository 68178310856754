import { useOutletContext } from "@remix-run/react";
import { CTA } from "~/components/landing-page/CTA/CTA";
import { SIGN_UP, EXPLORE } from "~/config/routes";
import image from "~/images/landing-page/banner-image-4.jpg";
import { OutletContext } from "~/types/shared";
import * as classes from "./ImageBanner.css";

export function ImageBanner() {
  const { userProfile } = useOutletContext<OutletContext>();

  return (
    <section className={classes.imageBanner}>
      <div className={classes.content}>
        <h3 className={classes.heading}>Ready to dive in?</h3>

        {userProfile ? (
          <>
            <p>
              View the latest logs, sites, trips, resorts, operators,
              liveaboards and centres.
            </p>
            <CTA text="Explore" href={EXPLORE} />
          </>
        ) : (
          <>
            <CTA text="Sign up" href={SIGN_UP} />
            <p>
              Sign up and join a community of divers passionate about adventure
              and ocean conservation
            </p>
          </>
        )}
      </div>
      <img
        className={classes.image}
        src={image}
        alt="Free diver swiming towards the surface next to a coral reef with small orange fish"
      />
    </section>
  );
}
