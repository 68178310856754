import {
  IconPhotoShare,
  IconScubaDiving,
  IconUsersGroup,
} from "@tabler/icons-react";
import { Banner } from "~/components/landing-page/ParallaxyBanners/Banner/Banner";

import bannerImg1 from "~/images/landing-page/banner-image-1.png";
import bannerImg2 from "~/images/landing-page/banner-image-2.png";
import bannerImg3 from "~/images/landing-page/banner-image-3.png";

import * as classes from "./ParallaxyBanners.css";

const bannerData = [
  {
    icon: <IconPhotoShare />,
    heading: "Share your dives, discover new adventures",
    copy: [
      "Dive deeper into your data with our advanced dive log platform. Log reef quality as well as uploading your favourite trip photos.",
      "Explore dive logs from thousands of divers and gain real-time insights on dive conditions across the globe.",
    ],
    imageSrc: bannerImg1,
    imageAlt: "",
  },
  {
    icon: <IconScubaDiving />,
    heading: "Designed by divers, for divers",
    copy: [
      "We're passionate about empowering the scuba diving community across the globe. Whether you're logging your 1st or 1,000th dive, our platform provides everything you need to record, explore, and connect.",
      "With real-time insights and a thriving community, you can easily plan your next trip while contributing to a growing diving resource.",
    ],
    imageSrc: bannerImg2,
    imageAlt: "",
    textRight: true,
  },
  {
    icon: <IconUsersGroup />,
    heading: "Connect with the global diving community",
    copy: [
      "Share dive logs, exchange tips, and discover new locations with the help of fellow diving enthusiasts.",
      "Whether you're looking for diving buddies, sharing marine conservation efforts, or planning trips with friends, our platform makes it easy to stay connected.",
    ],
    imageSrc: bannerImg3,
    imageAlt: "",
  },
];

export function ParallaxyBanners() {
  return (
    <div className={classes.container}>
      {bannerData.map((banner, i) => (
        <Banner
          key={i}
          index={i + 1}
          icon={banner.icon}
          heading={banner.heading}
          copy={banner.copy}
          imageSrc={banner.imageSrc}
          imageAlt={banner.imageAlt}
          textRight={banner.textRight}
        />
      ))}
    </div>
  );
}
