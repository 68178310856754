import { Paper } from "~/components/_common/Paper/Paper";
import { PageScroll } from "~/components/_common/PageScroll/PageScroll";
import { Hero } from "~/components/landing-page/Hero/Hero";
import { ImagesSlider } from "~/components/landing-page/ImagesSlider/ImagesSlider";
import { DataPledge } from "~/components/landing-page/DataPledge/DataPledge";
import { Snippets } from "~/components/landing-page/Snippets/Snippets";
import { ImageBanner } from "~/components/landing-page/ImageBanner/ImageBanner";
import { ParallaxyBanners } from "~/components/landing-page/ParallaxyBanners/ParallaxyBanners";

// Todo: Meta...

export default function Index() {
  return (
    <>
      <Paper offsetHeader>
        <Hero />
      </Paper>
      <ParallaxyBanners />
      <PageScroll>
        <ImagesSlider />
        <Paper>
          <DataPledge />
          <Snippets />
          <ImageBanner />
        </Paper>
      </PageScroll>
    </>
  );
}
